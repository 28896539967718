import React from "react"
import logo from '../../images/logo.png'


const  FooterBlock= ()=> {

    return(
        <div

        style={{ 
    
          
        
        }}
         
         
         
         
         >
            <div className=" uk-container uk-margin-large-top">
           <div  className="uk-grid uk-margin-large-top" data-uk-grid>
                <div className="uk-width-1-3@s">
                    <div className=" ">
                         <img src={logo} className="foooterLogo" />
                       
                    </div>
                </div>

                <div className="uk-width-1-3@s">
              
                </div>

                <div className="uk-width-1-3@s">
                <div class="uk-flex">
                    <a href="https://www.twitter.com/company/moliglobal" class="uk-icon-button uk-margin-medium-left  uk-margin-small-right" uk-icon="twitter"></a>
                    <a href="https://web.facebook.com/moolulab" class="uk-icon-button  uk-margin-small-right" uk-icon="facebook"></a>
                    <a href="https://www.linkedin.com/company/moolulab" class="uk-icon-button" uk-icon="linkedin"></a>
              </div>
                </div>

                
           </div>
        </div>



        {/**Social Links**/}
        <div className="uk-container socialFooterDiv uk-margin-medium-top">
           <div className="uk-grid" data-uk-grid>
               <div className="uk-width-1-3@s">
          <p class="light uk-text-small " style={{marginTop:'-30px'}}>© 2025  Moolu Venture Labs</p>
               </div>

               <div className="uk-width-1-3@s">
                 
               </div>

               <div className="uk-width-1-3@s">
               
               </div>
         </div>
          </div>

      



        </div>
    )
}


export default FooterBlock