import React from 'react'
import Layout from '../Layout/Layout'
import next from '../images/next.png'
const NextechCzar_application = () => {
  return (
    
    <Layout> 
    
    <div className='uk-margin-xlarge-top'>

        <div className='uk-container'>
             <img src= {next} />

             <h4 className='light'>Moolu Venture Lab has launched the NextechCzar Incubation Program, 
             an initiative designed to support startups in Nigeria. The primary objective of NextechCzar
              is to discover, support, and fund innovative solutions that have the potential to drive 
              economic growth, enhance national productivity, and create employment opportunities.
               The program is committed to fostering creativity in the digital sector and backing 
               novel solutions capable of bringing about cost-effective, scalable, and 
               transformative positive changes.</h4>

               <h4 className='light'>NextechCzar specifically targets technology-driven businesses in key sectors,
                 including financial services, agriculture, health, education, circular economy,
                  e-mobility, e-commerce, marketplaces, renewables, power, and climate. A panel 
                  of expert judges will assess applications from innovative businesses operating within these sectors.</h4>
        </div>

        <div className='uk-container uk-margin-large-top'>
           <h1 className='light uk-text-bold'>The program consists of three main stages:</h1>
           <h4 className='light uk-text-bold'>Initial Screening Phase: </h4>
           <p className='light'>Innovators will complete applications through a dedicated portal, providing comprehensive 
            information about their digital product or service, business model, and the minimum 
            viable product they have developed.</p>


            <h4 className='light uk-text-bold'>Acceleration Phase: </h4>
           <p className='light'> Successful applicants from the initial screening will undergo a rigorous 
           16-week accelerator program led by technical experts. This phase aims to mentor and equip 
           innovators with the essential tools for success.</p>


            <h4 className='light uk-text-bold'>Final Phase (Demo Day): </h4>
           <p className='light'>Finalists from the program will have the opportunity to present their
            pitches to a judging panel during the Impact Summit, a one-day event. The panel will 
            comprise technical and venture partners, as well as a mix of local and international
             technology industry experts and investors.</p>

            <div className='uk-margin-large-top'>
              <a href='https://vc4a.com/moli-academy/nextechczar-incubation-program/'>Click here to apply.</a>
            </div>


        

        </div>


        

    </div>


    </Layout>

  )
}

export default NextechCzar_application