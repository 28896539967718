import React from 'react'
import Layout from '../Layout/Layout'
import report from '../images/report.pdf'

import report2024 from '../images/moolu _venture_lab_report.pdf'



const Reports = () => {
  return (
    <Layout>

        <div className='uk-container uk-margin-xlarge-top uk-margin-xlarge-bottom'>
            <div className='uk-grid uk-margin-large-top' data-uk-grid>
                <div className='uk-width-1-2@s'>
                    <h2 className='light uk-text-bold'>Moolu Venture Lab's 2023 Annual Report </h2>
                    <p className='uk-margin-top light'>Moolu Venture Lab's 2023 Annual Report unveils a year of groundbreaking 
                    innovations and strategic milestones. From transformative projects to
                    impactful initiatives, the report highlights the Lab's commitment to 
                    fostering entrepreneurship and driving positive change. For a 
                    comprehensive dive into the Lab's achievements and future plans, <br/>
                    <a href={report} target='blank'>click here </a> to download the full report and explore the dynamic 
                    landscape of Moolu's venture ecosystem.
                    </p>
                </div>

                <div className='uk-width-1-2@s'>
                <h2 className='light uk-text-bold'>Moolu Venture Lab's 2024 Annual Report </h2>
                    <p className='uk-margin-top light'>Moolu Venture Lab 2024 Annual Report
                    This report highlights a transformative year of empowering entrepreneurs, 
                    driving innovation, and fostering sustainability across Africa. 2024 marks
                     a milestone in our journey toward creating inclusive growth opportunities.
                      Dive into our achievements and vision for the future. <br/>
                    <a href={report2024} target='blank'>click here </a> to download the full report and explore the dynamic 
                    landscape of Moolu's venture ecosystem.
                    </p>
                </div>
            </div>
        </div>

        </Layout>
  )
}

export default Reports