import React from 'react'
import image1 from '../../../images/1.png'
import image2 from '../../../images/2.png'
import image3 from '../../../images/3.png'
import image4 from '../../../images/4.png'
import image5 from '../../../images/5.png'
import image6 from '../../../images/6.png'
import image7 from '../../../images/7.png'
import image8 from '../../../images/8.png'
import image9 from '../../../images/9.png'

import image10 from '../../../images/10.png'









const Fourth_pane = () => {
  return (
    <div className='uk-container uk-margin-large-top'>
          <div className=''>    
          <h1 className='light'>How We Help Startups</h1>
             <div className='uk-grid' data-uk-grid>
              <div className='uk-width-1-4@s'>
                  <div>
                  <h3 className='light uk-text-bold'>Funding</h3> 
                  <p className='light'>We provide early-stage and growth capital to promising startups that align with our mission. </p> 
                    </div>
              </div>

              <div className='uk-width-1-4@s'>
              <div>
                  <h3 className='light  uk-text-bold'>Mentorship</h3> 
                  <p className='light'>Our network of seasoned entrepreneurs and industry leaders are committed to guiding and mentoring startup founders.  </p> 
                    </div>
              </div>

              <div className='uk-width-1-4@s'>
              <div>
                  <h3 className='light   uk-text-bold'>Access to Markets</h3> 
                  <p className='light'>We facilitate connections with key stakeholders, potential customers, and distribution channels to help startups reach underserved markets at scale </p> 
                    </div>
              </div>

              <div className='uk-width-1-4@s'>
              <div>
                  <h3 className='light    uk-text-bold'>Collaborative Community</h3> 
                  <p className='light'>We foster a collaborative and supportive community where founders can connect, learn from each other, and share best practices </p> 
                    </div>
              </div>
          </div>

          </div>




s
          <div className='uk-container uk-margin-large-top'>

          <div className=''>    
          <h1 className='light'>Partners</h1>

          <div className='uk-flex'>

             <div>
              <img src={image1} className='logoStyle'/>
             </div>

             <div>
              <img src={image2}  className='logoStyle'/>
             </div>

             <div>
              <img src={image3}  className='logoStyle'/>
             </div>
             <div>

              <img src={image4}  className='logoStyle'/>
             </div>

             <div>
              <img src={image5}  className='logoStyle'/>
             </div>


             
             <div>
              <img src={image6}  className='logoStyle'/>
             </div>


             
             <div>
              <img src={image7}  className='logoStyle'/>
             </div>


             
             <div>
              <img src={image8}  className='logoStyle'/>
             </div>


             
             <div>
              <img src={image9}  className='logoStyle'/>
             </div>



             <div>
              <img src={image10}  className='logoStyle'/>
             </div>

            


          </div>



          </div>

          </div>











    </div>
  )
}

export default Fourth_pane